import React from 'react';

const NoCustomer = (props) => {
  const { product } = props;

  const pricingArray = product && product.loyalty && product.loyalty.pricing ? product.loyalty.pricing : [];

  const handleLogin = () => {
    const currentLink = window.location.href; 
    window.location.href = `https://www.jordanwinery.com/c7-rewards-login/?return_url=${currentLink}`;
  }
  
  return (
    <>
      {pricingArray.length > 0 && (
        <div className="widget-pricing-nocustomer">
          <button type="button" onClick={handleLogin} className="c7-button">
            Member Login
          </button>

        </div>
      )}
    </>
  );
};

export default NoCustomer;