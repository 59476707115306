import Cookies from 'js-cookie';

export const axiosHeader = () => ({
    headers: {
      Authorization: Cookies.get(`customerToken`),
      Pragma: 'no-cache',
      tenant: getTenantId()
    }
  });

  export const getTenantId = () => {
    const scriptTag = document.getElementById('c7-javascript');    
    const tenantId = scriptTag.getAttribute('data-tenant');
    // const tenantId = 'jordan-vineyard-and-winery';

    return tenantId;
  };

  export const getV1RootUrl = () => {   
    return 'https://api.commerce7.com/v1';
  };


  export const publicAxiosHeader = () => {
    if (Cookies.get(`customerToken`)) {
      return axiosHeader();
    }
    return {
      headers: { Pragma: 'no-cache', tenant: getTenantId() }
    };
  };
