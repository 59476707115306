import React, { useState } from 'react';

const NoCustomer = (props) => {
  const { settings } = props;

const handleLogin = () => {
  const currentLink = window.location.href; 
  window.location.href = `https://www.jordanwinery.com/c7-rewards-login/?return_url=${currentLink}`;
}

  return (
    <div className="widget-loyalty-nocustomer">
    <div className="widet-points-on-signup">
      Receive {settings.loyalty.pointsOnCustomerCreate} Bonus Points
    </div>
    <a href="/loyalty-program/join" className="c7-button">
      Join Now
    </a>
    <br />
    Already a member?
    <br />
    <button type="button" onClick={handleLogin} className="c7-button">
      Member Login
    </button>
  </div>
  );
};

export default NoCustomer;