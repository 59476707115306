/* global document */
import React from 'react';
// import { createRoot } from 'react-dom/client';

import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const c7Content = document.getElementById('c7-content');

if (c7Content) {
  const root = ReactDOM.createRoot(document.getElementById('c7-content'));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  const content = document.createElement('div');
  content.id = 'c7-no-content';
  document.body.appendChild(content);
  const container = document.getElementById('c7-no-content');
  const root = ReactDOM.createRoot(container);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

reportWebVitals();
