import Cookies from 'js-cookie';
import { createCart, addItem } from '../../fetchers/cart';

export const addCartItem = async(item, analytics) => {
    // Esure we have a cart
    const cart = await create();

    try{
      const cartWithItem = await addItem(cartId(), item);
      return cartWithItem;
    }catch (err){

      return err.response.data;
    }

}

export const create = async () => {
    let isCreating = true;
    let cart;
    if (!cartId()) {
      const newCart = {
        channel: 'Web',
        connectionInformation: {
          userAgent: navigator.userAgent
        }
      };
      if (Cookies.get('customerToken')) {
        newCart.customerId = jwtDecode(Cookies.get('customerToken')).sub;
      }
      if (Cookies.get('c7EmailId')) {
        newCart.additionalInfo = {
          emailId: Cookies.get('c7EmailId')
        };
      }
      if (Cookies.get('c7metaData')) {
        newCart.metaData = JSON.parse(Cookies.get('c7metaData'));
      }
      try {

        cart = await createCart(newCart);
   
        Cookies.set('cartId', cart.id, { expires: 365 });
      } catch (err) {
        delete newCart.metaData;
        cart = await createCart(newCart);
        Cookies.set('cartId', cart.id, { expires: 365 });
      }
    }
    isCreating = false;
    return cart;
  }

  const cartId = () => Cookies.get('cartId');