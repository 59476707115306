import React from "react";
import {
    useQuery,
  } from '@tanstack/react-query';
  import { getForWeb } from '../../fetchers/settings';
  import { getCustomer } from '../../fetchers/customer';
  import NoCustomer from './NoCustomer';
  import Customer from './Customer';


const LoyaltyWidget = (props) => {
    const { slug } = props;

    // GET SETTINGS
    const { isError: isErrorSettings, isSuccess: isSuccessSettings, isLoading: isLoadingSettings, data: dataSettings, error: errorSettings } = useQuery(
        ['settings'],
        getForWeb,
        { staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false}
    );

    // GET CUSTOMERS
    const { isError: isErrorCustomer, isSuccess: isSuccessCustomer, isLoading: isLoadingCustomer, data: dataCustomer, error: errorCustomer } = useQuery(
        ['customer'],
        getCustomer,
        { staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false}
    );

    if (!dataSettings) {
        return null;
    }

    if(isLoadingCustomer){
        return <div>Loading</div>;
    }

    if(dataCustomer){
        return <Customer settings={dataSettings} customer={dataCustomer} />
    }

    return <NoCustomer settings={dataSettings} />;
};

export default LoyaltyWidget;