import React, { useState } from 'react';
import {
  currencyFormat,
  convertStringToInteger,
  formatWithCommas
} from 'common/helpers/numberHelper';

import {
  addCartItem
} from 'common/helpers/cartHelper';


const Customer = (props) => {
  const { product, settings, customer } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { price, sku } = product.variants[0];

  const pricingArray = product && product.loyalty && product.loyalty.pricing ? product.loyalty.pricing : [];
  const currentLevel = pricingArray.find(
    (level) => level.loyaltyTierId === customer?.loyalty?.loyaltyTierId
  );

  let initialQuantity = 1;
  let initialQuantityWithPoints = 0;
  if (currentLevel) {
    initialQuantity = 0;
    initialQuantityWithPoints = 1;
  }
  const [quantity, setQuantity] = useState(initialQuantity);
  const [quantityWithPoints, setQuantityWithPoints] = useState(
    initialQuantityWithPoints
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let cart;
    if (
      quantity === '' ||
      quantityWithPoints === '' ||
      Number.isNaN(quantity) ||
      Number.isNaN(quantityWithPoints)
    ) {
      setIsSubmitting(false);
      setErrorMessage('Invalid Quantity');
      return;
    }
    const qty = convertStringToInteger(quantity);
    const qtyWithPoints = convertStringToInteger(quantityWithPoints);
    if (qty < 0 || qtyWithPoints < 0) {
      setIsSubmitting(false);
      setErrorMessage('Invalid Quantity');
      return;
    }
    const analytics = {
      viewType: 'Loyalty Widget'
    };

    const item2 = {
      quantity: qtyWithPoints,
      sku,
      itemData: { useLoyaltyPoints: true }
    };
    const quantityWithPointsFunc = async () => {
      if (qtyWithPoints > 0) {
        // dispatch(addCartItem(item2, analytics, setIsSubmitting(false)));
        return await addCartItem(item2, analytics);
        
      } else {
        setIsSubmitting(false);
      }
    };

    if (qty > 0) {
      const item = {
        quantity: qty,
        sku
      };
      // dispatch(addCartItem(item, analytics, quantityWithPointsFunc));
      cart = await addCartItem(item, analytics);

    } else if (qtyWithPoints > 0) {
      cart = await quantityWithPointsFunc();
    } else {
      setIsSubmitting(false);
    }

    if (cart && cart.id){
      window.c7action.reloadCart();
      window.c7action.showSideCart();
      setIsSubmitting(false);
    }else{
      if(cart && cart.message){
        setErrorMessage(cart.message);
      }
    }

  };

  const handleQuantity = (e) => {
    const input = e.target.value;
    const qty = input.replace(/[^0-9]/g, '');
    setQuantity(qty);
    setErrorMessage(null);
    setIsSubmitting(false);
  };

  const handleQuantity2 = (e) => {
    const input = e.target.value;
    const qty = input.replace(/[^0-9]/g, '');
    setQuantityWithPoints(qty);
    setErrorMessage(null);
    setIsSubmitting(false);
  };

  return (
<div className="widget-pricing-add-to-cart">
      <form onSubmit={handleSubmit}>
        {errorMessage && (
          <div className="c7-product__error" aria-describedby="qtyerror">
            Error: {errorMessage}
          </div>
        )}
        <CurrentLevel
          currentLevel={currentLevel}
          settings={settings}
          onQuantityChange={handleQuantity2}
          quantity={quantityWithPoints}
        />
        <NoCurrentLevel
          currentLevel={currentLevel}
          customer={customer}
          settings={settings}
          pricingArray={pricingArray}
        />
        <div className="widget-pricing-add-to-cart-without-points">
          <input type="text" value={quantity} onChange={handleQuantity} />
          <span>@</span> {currencyFormat(price, settings)}
        </div>
        <button type="submit" className="c7-button" disabled={isSubmitting}>
          Add To Cart
        </button>
      </form>
    </div>
  );
};

const NoCurrentLevel = (props) => {
  const { currentLevel, settings, customer, pricingArray } = props;
  if (!customer) {
    return null;
  }
  if (pricingArray.length === 0) {
    return null;
  }

  const { lifetimeValue } = customer.orderInformation;
  const { tiers } = settings.loyalty;
  if (currentLevel) {
    return null;
  }
  let nextTier = null;
  const reversedTiers = tiers.reverse();
  reversedTiers.forEach((tier) => {
    if (!nextTier && tier.lifetimeValueToQualify > lifetimeValue) {
      nextTier = tier;
    }
  });
  if (!nextTier) {
    return null;
  }
  return (
    <>
      <div className="widget-pricing-next-tier-message">
        {currencyFormat(
          nextTier.lifetimeValueToQualify - lifetimeValue,
          settings
        )}{' '}
        to achieve {nextTier.title} status
      </div>
    </>
  );
};

const CurrentLevel = (props) => {
  const { currentLevel, settings, onQuantityChange, quantity } = props;
  if (!currentLevel) {
    return null;
  }
  return (
    <div className="widget-pricing-add-to-cart-with-points">
      <input type="text" value={quantity} onChange={onQuantityChange} />
      <span>@</span> {currencyFormat(currentLevel.price, settings)}{' '}
      <span>+</span> {formatWithCommas(currentLevel.points)} points
    </div>
  );
};

export default Customer;