import React from 'react';
import { currencyFormat, formatWithCommas } from 'common/helpers/numberHelper';

const Customer = (props) => {
    const { settings, customer } = props;

    return (
        <div className="widget-loyalty-customer">
            <h3>
            Your Account <a href="/profile">Link</a>
            </h3>
            <Points customer={customer} />
            <Tier customer={customer} />
            <div className="widet-ltv-value">
            {currencyFormat(
                customer.orderInformation.lifetimeValue,
                settings
            )}
            </div>
            <div className="widet-ltv-label">Spending History</div>
            <NextTier customer={customer} settings={settings} />
        </div>
        );
    };

    const NextTier = (props) => {
        const { customer, settings } = props;
        const { lifetimeValue } = customer.orderInformation;
        const { tiers } = settings.loyalty;
      
        let nextTier = null;
        const reversedTiers = tiers.reverse();
        reversedTiers.forEach((tier) => {
          if (!nextTier && tier.lifetimeValueToQualify > lifetimeValue) {
            nextTier = tier;
          }
        });
        if (!nextTier) {
          return null;
        }
        return (
          <>
            <div className="widet-ltvneeded-value">
              {currencyFormat(
                nextTier.lifetimeValueToQualify - lifetimeValue,
                settings
              )}
            </div>
            <div className="widet-ltvneeded-label">
              To achieve {nextTier.title} Status
            </div>
          </>
        );
      };
      
      const Tier = (props) => {
        const { customer } = props;
        if (!customer.loyalty?.tier) {
          return null;
        }
        return (
          <>
            <div className="widet-tier-value">{customer.loyalty.tier}</div>
            <div className="widet-tier-label">Membership Level</div>
          </>
        );
      };

    const Points = (props) => {
        const { customer } = props;
        if (customer.loyalty && customer.loyalty.points) {
          return (
            <>
              <div className="widet-points-value">
                {formatWithCommas(customer.loyalty.points)} <span>points</span>
              </div>
              <div className="widet-points-label">Current Rewards Balance</div>
            </>
          );
        }
        return (
          <>
            <div className="widet-points-value">
              0 <span>points</span>
            </div>
            <div className="widet-points-label">Current Rewards Balance</div>
          </>
        );
      };

export default Customer;