import axios from 'axios';
import {
    publicAxiosHeader,
    axiosHeader,
    getV1RootUrl
  } from '../common/helpers/apiHelper';

export const getReservationType = async ({ queryKey }) => {

    const [_key, { slug }] = queryKey

    let urlString = '';

    const response = await axios.get(
        `${getV1RootUrl()}/reservation-type/slug/${slug}/for-web?${urlString}`,
        publicAxiosHeader()
      );
    const reservationType = response.data;

    return reservationType;
}

