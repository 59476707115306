import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import RequestPricing from './widgets/RequestPricing';
import EventPricing from './widgets/EventPricing';
import ReservationPricing from './widgets/ReservationPricing';
import Loyalty from './widgets/Loyalty';

const queryClient = new QueryClient();

function App() {
  const eventPricing = document.getElementById('c7-event-pricing-jv');
  const requestPricing = document.getElementById('c7-request-pricing-jv');
  const reservationPricing = document.getElementById('c7-reservation-pricing-jv');
  const loyalty = document.getElementById('c7-loyalty-widget-jv');


  // reservationPricing.addEventListener("click", event => {
  //   console.log('CLICK EVENT LISTENER', event.target.innerText);
  //   if(event.target.innerText === 'CHECK AVAILABILITY'){
  //     console.log('CLICK ON BUTTON');
  //     const data = document.getElementsByClassName('c7-reservation-type__details');
  //     console.log('data', data)
  //     Array.prototype.forEach.call(data, function(element) {
  //       element.insertAdjacentHTML('afterbegin', '<div class="widget-pricing-add-to-cart-with-points"><label for="payWithPointsTrue"><input name="payWithLoyaltyPoints" type="radio" id="payWithPointsTrue" value="true" data-uw-rm-form="nfx">$65.00 <span>+</span> 1800 <span class="points">points</span><span class="price-title">/ person</span></label></div><div class="c7-product__variant__price"><label for="payWithPointsFalse"><input name="payWithLoyaltyPoints" type="radio" id="payWithPointsFalse" value="false" checked="" data-uw-rm-form="nfx"><div class="c7-product__variant__price"><div class="c7-product__variant__price__normal">$80.<span>00</span><span class="c7-product__variant__price__title">per person</span></div>');
  //   });
  //   }


  // })

  
  return (
    <QueryClientProvider client={queryClient}>
      {eventPricing && (<EventPricing slug={eventPricing.getAttribute('data-product-slug')}  />)}
      {requestPricing && (<RequestPricing slug={requestPricing.getAttribute('data-product-slug')} />)}
      {reservationPricing && (<ReservationPricing slug={reservationPricing.getAttribute('data-reservation-type-slug')} />)}
      {loyalty && (<Loyalty />)}
    </QueryClientProvider>
  );
}

export default App;
