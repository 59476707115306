import React from 'react';
import ReactDOM from 'react-dom';

import Widget from './requestPricingWidget';

const RequestPricing = (props) => {
  return ReactDOM.createPortal(
    <>
      <Widget slug={props.slug} />
    </>,
    document.getElementById('c7-request-pricing-jv')
  );
}  
// {
//   return(
//     <Widget slug={props.slug} />
//   )
// }

export default RequestPricing;