import axios from 'axios';
import {
    axiosHeader,
    getV1RootUrl
  } from '../common/helpers/apiHelper';

export const getCustomer = async () => {
    const response = await axios.get(`${getV1RootUrl()}/customer/self`, axiosHeader());
    const customer = response.data;

    return customer;
}