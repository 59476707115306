import React from 'react';
import ReactDOM from 'react-dom';

import Widget from './reservationPricingWidget';

const ReservationPricing = (props) => {
  return ReactDOM.createPortal(
    <>
      <Widget slug={props.slug} />
    </>,
    document.getElementById('c7-reservation-pricing-jv')
  );
}
  // {
  //   return(
  //     <Widget slug={props.slug} />
  //   )
  // }

export default ReservationPricing;