import axios from 'axios';
import {
    publicAxiosHeader,
    axiosHeader,
    getV1RootUrl
  } from '../common/helpers/apiHelper';

export const listLoyaltyTiers = async () => {
    const response = await axios.get(`${getV1RootUrl()}/loyalty-tier/for-web`, publicAxiosHeader());
    const listLoyaltyTiers = response.data;

    return listLoyaltyTiers;
}