import React from 'react';
import ReactDOM from 'react-dom';

import Widget from './loyaltyWidget';

const Loyalty = (props) => 
  ReactDOM.createPortal(
    <>
      <Widget slug={props.slug} />
    </>,
    document.getElementById('c7-loyalty-widget-jv')
  );
  // {
  //   return(
  //     <Widget slug={props.slug} />
  //   )
  // }

export default Loyalty;