import React from "react";
import {
    useQuery,
  } from '@tanstack/react-query';
  import { getForWeb } from '../../fetchers/settings';
  import { getCustomer } from '../../fetchers/customer';
  import { getReservationType } from '../../fetchers/reservationType';
  import { listLoyaltyTiers } from '../../fetchers/loyaltyTiers';
  import AvailabilityWidget from './AvailabilityWidget'
  import NoCustomer from './NoCustomer';
  import Pricing from './Pricing';


const ReservationPricing = (props) => {
    const { slug } = props;

    // GET SETTINGS
    const { isError: isErrorSettings, isSuccess: isSuccessSettings, isLoading: isLoadingSettings, data: dataSettings, error: errorSettings } = useQuery(
        ['settings'],
        getForWeb,
        { staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false}
    );

    // GET CUSTOMERS
    const { isError: isErrorCustomer, isSuccess: isSuccessCustomer, isLoading: isLoadingCustomer, data: dataCustomer, error: errorCustomer } = useQuery(
        ['customer'],
        getCustomer,
        { staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false}
    );

    // GET RESERVATION TYPE
    const { isError: isErrorReservationType, isSuccess: isSuccessReservationType, isLoading: isLoadingReservationType, data: dataReservationType, error: errorReservationType } = useQuery(
        { queryKey: ['reservationType', {slug}],
        queryFn: getReservationType,
        staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false
    });


    // GET LOYALTY TIERS
    const { isError: isErrorLoyaltyTiers, isSuccess: isSuccessLoyaltyTiers, isLoading: isLoadingLoyaltyTiers, data: dataLoyaltyTiers, error: errorLoyaltyTiers } = useQuery(
        { queryKey: ['loyaltyTiers'],
        queryFn: listLoyaltyTiers,
        staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false
    });

    if (!dataSettings) {
        return null;
    }

    if (isLoadingCustomer || isLoadingLoyaltyTiers || isLoadingReservationType) {
        return <div>Loading</div>;
    }

    if (!dataReservationType) {
        return <div>Loading</div>;
    }
    


    return (
        <div className="widget-pricing">
            <Pricing reservationType={dataReservationType} customer={dataCustomer} loyaltyTiers={dataLoyaltyTiers} settings={dataSettings} />
            {!dataCustomer && <NoCustomer reservationType={dataReservationType} />}
            {/* <AvailabilityWidget reservationType={dataReservationType} settings={dataSettings}  /> */}
            <div className="c7-reservation-availability" data-reservation-type-slug={slug}></div> 
        </div>
    );
};

export default ReservationPricing;