import axios from 'axios';
import {
    getV1RootUrl,
    publicAxiosHeader
  } from '../common/helpers/apiHelper';

export const createCart = async (cart) => {
  const response = await axios.post(`${getV1RootUrl()}/cart`, cart, publicAxiosHeader());
  const createCart = response.data;
  return createCart;
}

export const addItem = async (cartId, item) => {

   const response =  await axios.post(
    `${getV1RootUrl()}/cart/${cartId}/item`,
    item,
    publicAxiosHeader()
  );

  const cart = response.data;
  return cart;

}