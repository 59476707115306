import React, { useState } from 'react';
import classNames from 'classnames';
import { currencyFormat, formatWithCommas } from '../../common/helpers/numberHelper';

const Pricing = (props) => {
  const { reservationType, customer, loyaltyTiers, settings } = props;

  const pricingArray = reservationType && reservationType.loyalty && reservationType.loyalty.pricing ? reservationType.loyalty.pricing : [];
  if (pricingArray.length === 0) {
    return (
        <RetailPrice reservationType={reservationType} settings={settings} />
    );
  }

  return (
    <>
    <RetailPrice reservationType={reservationType} settings={settings} />
    
    <div className="widget-pricing-wrapper">
      <div className="widget-pricing-title">Member Pricing</div>
      {pricingArray.map((obj) => {
        const { points, price, loyaltyTierId } = obj;
        const tier = loyaltyTiers.filter(loyalTier => { return loyalTier.id === loyaltyTierId })
        return (
          <div
            className={classNames(
              'widget-pricing-level',
              isCustomerTier(customer, tier) && 'selected'
            )}
            key={loyaltyTierId}
          >
            <span className="widget-pricing-level-tier">{tier[0].title}</span>
            <span className="widget-pricing-level-price">
              {' '}
              {currencyFormat(price, settings)} +
            </span>
            <span className="widget-pricing-level-points">
              {' '}
              {formatWithCommas(points)} points
            </span>
          </div>
        );
      })} 
    </div>
    </>
  );
};

const isCustomerTier = (customer, tier) => {
  if (!customer) {
    return false;
  }
  if (!customer?.loyalty?.tier) {
    return false;
  }
  if (customer.loyalty.tier === tier.title) {
    return true;
  }
  return false;
};

const RetailPrice = (props) => {
    const { reservationType, settings } = props;
    const price = reservationType && reservationType.options && reservationType.options.length > 0 ? reservationType.options[0].price : 0;
    return (
      <div className="widget-retail-pricing-wrapper">
        <div className="widget-pricing-title">Price</div>
        <span className="widget-retail-price">
          {currencyFormat(price, settings)}
        </span>
      </div>
    );
  };

export default Pricing;