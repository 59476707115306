import axios from 'axios';
import {
    publicAxiosHeader,
    axiosHeader,
    getV1RootUrl
  } from '../common/helpers/apiHelper';

export const getProduct = async ({ queryKey }) => {

    const [_key, { slug }] = queryKey

    let urlString = '';
    // if (cartId) {
    //     urlString += `&cartId=${cartId}`;
    // }
    // if (customerId) {
    //     urlString += `&customerId=${customerId}`;
    // }

    const response = await axios.get(
        `${getV1RootUrl()}/product/slug/${slug}/for-web?${urlString}`,
        publicAxiosHeader()
      );
    const product = response.data;

    return product;
}

