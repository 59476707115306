export const currencyFormat = (number, settings) => {
    if (Number.isNaN(number)) {
      throw new Error('Invalid Number');
    }
    const { currency } = settings;
    if (
      currency !== 'CAD' &&
      currency !== 'USD' &&
      currency !== 'AUD' &&
      currency !== 'NZD' &&
      currency !== 'ZAR' &&
      currency !== 'EUR' &&
      currency !== 'GBP'
    ) {
      throw new Error('Invalid Currency');
    }
    const results = numberWithCommas(decimalFormat(number));
    if (currency === 'EUR') {
      if (number >= 0) {
        return `€${results}`;
      }
      return `€-${results.replace(/-/g, '')}`;
    }
    if (currency === 'GBP') {
      if (number >= 0) {
        return `£${results}`;
      }
      return `-£${results.replace(/-/g, '')}`;
    }
    if (currency === 'ZAR') {
      if (number >= 0) {
        return `R${results}`;
      }
      return `R(${results.replace(/-/g, '')})`;
    }
    if (number >= 0) {
      return `$${results}`;
    }
    return `$(${results.replace(/-/g, '')})`;
  };
  
  export const decimalFormat = (number, noScale) => {
    if (Number.isNaN(number)) {
      throw new Error('Invalid Number');
    }
    if (noScale) {
      const results = parseFloat(number);
      return results.toFixed(2);
    }
    const results = parseFloat(number / 100);
    return results.toFixed(2);
  };
  //
  // export const convertToDecimalNumber = number => {
  //   if (Number.isNaN(number)) {
  //     throw new Error('Invalid Number');
  //   }
  //   const results = parseFloat(number / 100);
  //   return parseFloat(results.toFixed(2));
  // };
  
  export const integerFormat = (number) => {
    if (Number.isNaN(number)) {
      throw new Error('Invalid Number');
    }
    const results = Math.round(number);
    return results;
  };
  
  export const convertStringToInteger = (string) => {
    const number = parseFloat(string);
    if (Number.isNaN(number)) {
      throw new Error('Invalid Number');
    }
    const results = Math.round(number);
    return results;
  };
  
  export const convertStringToFloat = (string, returnError = false) => {
    const number = parseFloat(string);
    if (Number.isNaN(number) && returnError) {
      throw new Error('Invalid Number');
    }
    if (Number.isNaN(number)) {
      return string;
    }
    return number;
  };
  
  export const formatWithCommas = (x) => {
    const data = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return data;
  }

  const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');