import React from "react";
import {
    useQuery,
  } from '@tanstack/react-query';
  import { getForWeb } from '../../fetchers/settings';
  import { getCustomer } from '../../fetchers/customer';
  import { getProduct } from '../../fetchers/product';
  import { listLoyaltyTiers } from '../../fetchers/loyaltyTiers';
  import NoCustomer from './NoCustomer';
  import Pricing from './Pricing';


const RequestPricing = (props) => {
    const { slug } = props;

    // GET SETTINGS
    const { isError: isErrorSettings, isSuccess: isSuccessSettings, isLoading: isLoadingSettings, data: dataSettings, error: errorSettings } = useQuery(
        ['settings'],
        getForWeb,
        { staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false}
    );

    // GET CUSTOMERS
    const { isError: isErrorCustomer, isSuccess: isSuccessCustomer, isLoading: isLoadingCustomer, data: dataCustomer, error: errorCustomer } = useQuery(
        ['customer'],
        getCustomer,
        { staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false}
    );

    // GET PRODUCT
    const { isError: isErrorProduct, isSuccess: isSuccessProduct, isLoading: isLoadingProduct, data: dataProduct, error: errorProduct } = useQuery(
        { queryKey: ['product', {slug}],
        queryFn: getProduct,
        staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false
    });

    // GET LOYALTY TIERS
    const { isError: isErrorLoyaltyTiers, isSuccess: isSuccessLoyaltyTiers, isLoading: isLoadingLoyaltyTiers, data: dataLoyaltyTiers, error: errorLoyaltyTiers } = useQuery(
        { queryKey: ['loyaltyTiers'],
        queryFn: listLoyaltyTiers,
        staleTime: 'Infinity', retry: false, refetchOnWindowFocus:false
    });

    if (!dataSettings) {
        return null;
    }

    if (isLoadingCustomer || isLoadingLoyaltyTiers || isLoadingProduct) {
        return <div>Loading</div>;
    }

    if (!dataProduct) {
        return <div>Loading</div>;
    }

    return (
        <div className="widget-pricing">
            <Pricing product={dataProduct} customer={dataCustomer} loyaltyTiers={dataLoyaltyTiers} settings={dataSettings} />
            {!dataCustomer && <NoCustomer product={dataProduct} />}
        </div>
    );

};

export default RequestPricing;