import axios from 'axios';
import {
    publicAxiosHeader,
    getV1RootUrl
  } from '../common/helpers/apiHelper';

export const getForWeb = async () => {
    const response = await axios.get(`${getV1RootUrl()}/setting/for-web?version=V2`, publicAxiosHeader());
    const settings = response.data;

    return settings;
}